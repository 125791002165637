/*
 * @Author: qiankun
 * @LastEditors: fangjun
 */
import { defineStore } from 'pinia'

export const useWsStore = defineStore('websocket', {
  state: () => ({
    websocket: null,
    ready: false
  }),
  actions: {
    create(url) {
      this.websocket = new WebSocket(url)
      this.ready = false
    },
    close() {
      this.websocket.close()
      this.websocket = null
    },
    setReady(value) {
      this.ready = value
    }
  }
})
